body{
background: #003055; 
}
a{text-decoration: none;}
.bookboxm{
  
        .col-md-4{
                .card{
                background: rgba(86, 247, 255, 0.2);
                color: #fff;
                border: 0 !important;
                position: relative;
                overflow: hidden;
                &:before {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background:rgba(255, 255, 255, 0.2);
                    transition: .5s;
                    z-index: -1;
                  }
                  &:hover:before{
                    top: 0 !important; 
                    }
                }
            &:nth-child(2){
                .card{
                    background: rgba(255, 222, 222, 0.2) !important;
                }
            }
        }
}
    .cardBox{
        .listIcon{
            // margin: auto;
            background: #fff;
            border-radius: 90px; 
            text-align: center; 
            width: 70px;
            height: 70px;
            i{
                font-size: 30px;
                color: #7c00b6;
                display: block;
                line-height: 70px;
            }

        }
        h5{
            text-transform: uppercase;
            font-weight: 400;
            margin-top: 1rem; 
            color: #fff;
            margin: 0;
        }
        h2{
            margin: 0;
            padding: 0;
        }
    }

.EquipmentsBox{
    border: 0;
    h5{
        margin: 0;
        font-weight: 400;
        font-size: 1rem;
        color: #0d6efd;
    }
    p{
        margin: 0;
        padding: 0;
        color: #305591;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 500;
    }
    &:hover{
        h5{
            color: #b60000;
        }
    }
}





















